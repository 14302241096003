import { Deserializable } from '../protocols/deserializable';
import { LookAheadItem } from '../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { ProductType } from '../../utils/product-type-definition';
import { SmartFilterCategory } from './smart-filter-category';
import { Variant } from '../product/dto/variant';
import { SmartFilterSystemIdentifiersType } from '../../utils/smart-filter-system-identifiers-type-definition';
import { DisplayAttributeGroupDetails } from '../product/dto/display-attribute-group-details';
import { exists } from '../../functions/exists';
import { SecondaryCannabinoid } from '../enum/dto/secondary-cannabinoid';
import { Terpene } from '../enum/dto/terpene';
import { PrimaryCannabinoid } from '../enum/shared/primary-cannabinoid.enum';
import { StringUtils } from '../../utils/string-utils';
import { Cannabinoid } from '../enum/dto/cannabinoid';

/**
 * TODO remove comment after SF V3
 * new:
 * itemCount
 * primarySort
 * secondarySort
 * usePurpose
 * packagedQuantities
 * unitSizes
 * productName
 * brandName
 * pricingCodes
 * requirePricingTier
 * labelIds
 * badgeIds
 */
export class SmartFilter implements Deserializable, LookAheadItem, UniquelyIdentifiable {

  public locationId: number = null;
  public id: string = '';
  public ignoredVariantIds: string[] = [];
  public dateCreated: number = null;
  public lastModified: number = null;
  public lastUpdated: number = null;
  public categoryId: string = '';
  public advancedFilter: boolean = null;
  // General Info
  public name: string = '';
  public itemCount: number = null;
  public primarySort: string = '';
  public secondarySort: string = '';
  public category: SmartFilterCategory;
  public systemIdentifier: SmartFilterSystemIdentifiersType;
  // Filter Attributes
  public usePurpose: string = '';
  public productType: ProductType = null;
  public variantType: string = '';
  public classification: string = '';
  public minPrice: number = null;
  public maxPrice: number = null;
  public minSecondaryPrice: number = null;
  public maxSecondaryPrice: number = null;
  // Cannabinoids
  public presentCannabinoids: Cannabinoid[];
  // Terpenes
  public topTerpene: string = '';
  public minTotalTerpene: number = null;
  public maxTotalTerpene: number = null;
  public presentTerpenes: Terpene[];
  // Advanced Filter Attributes
  public packagedQuantities: string = '';
  public unitSizes: string = '';
  public productName: string = '';
  public brandName: string = '';
  public strain: string = '';
  public searchTerm: string = ''; // General Search Term
  public minInventory: number = null;
  public maxInventory: number = null;
  public pricingCodes: string = '';
  public activeSale: boolean = null;
  public requirePricingTier: boolean = null;
  public labelIds: string[] = [];
  public badgeIds: string[] = [];
  public hidden: boolean = null;
  public isCompanySmartFilter: boolean = null;
  public isCuratedSmartFilter: boolean = null;

  // Cannabinoid and Terpene Properties are added via initializeCannabinoidsAndTerpeneProperties()
  // These properties are of type number.
  // cannabinoid example properties: minTHC, maxTHC, minCBLA, maxCBLA, minTAC, maxTAC, etc.
  // terpene example properties: minBisabolol, maxBisabolol, minLimonene, maxLimonene, etc.
  // NOTE: terpene enums are capitalized, but terpene properties are camelCase.

  /**
   * Adds [minCannabinoid] and [maxCannabinoid] properties to the SmartFilter object.
   * Adds [minTerpene], and [maxTerpene] properties to the DisplayAttribute object.
   * NOTE: terpene enums are capitalized, but terpene properties are camelCase.
   */
  protected initializeCannabinoidsAndTerpeneProperties(): void {
    Object.values(PrimaryCannabinoid)?.forEach(cannabinoid => {
      this[`min${cannabinoid}`] = this[`min${cannabinoid}`] || null; // This will make 0 become null
      this[`max${cannabinoid}`] = this[`max${cannabinoid}`] || null; // This will make 0 become null
    });
    this.presentCannabinoids = Array.from(this.presentCannabinoids || []);
    Object.values(SecondaryCannabinoid)?.forEach(secondaryCannabinoid => {
      this[`min${secondaryCannabinoid}`] = this[`min${secondaryCannabinoid}`] || null; // This will make 0 become null
      this[`max${secondaryCannabinoid}`] = this[`max${secondaryCannabinoid}`] || null; // This will make 0 become null
    });
    this.presentTerpenes = Array.from(this.presentTerpenes || []);
    Object.values(Terpene)?.forEach(terpene => {
      const pascalCaseTerpene = StringUtils.toPascalCase(terpene);
      this[`min${pascalCaseTerpene}`] = this[`min${pascalCaseTerpene}`] || null; // This will make 0 become null
      this[`max${pascalCaseTerpene}`] = this[`max${pascalCaseTerpene}`] || null; // This will make 0 become null
    });
  }

  onDeserialize() {
    this.initializeCannabinoidsAndTerpeneProperties();
    if (this.minTotalTerpene === 0) this.minTotalTerpene = null;
    if (this.maxTotalTerpene === 0) this.maxTotalTerpene = null;

    if (this.minInventory === 0) this.minInventory = null;
    if (this.maxInventory === 0) this.maxInventory = null;
    if (this.minPrice === 0) this.minPrice = null;
    if (this.maxPrice === 0) this.maxPrice = null;
    if (this.itemCount === 0) this.itemCount = null;
    this.ignoredVariantIds = Array.from(this.ignoredVariantIds || []);
    this.labelIds = Array.from(this.labelIds || []);
    this.badgeIds = Array.from(this.badgeIds || []);
    this.category = window.injector.Deserialize.instanceOf(SmartFilterCategory, this.category);
  }

  // Expected go model:
  // https://github.com/mobilefirstdev/budsense-shared/blob/dev/models/DTO/SmartFilterDTO.go
  onSerialize() {
    const dto = Object.create(SmartFilter.prototype);
    dto.locationId = this.locationId;
    dto.id = this.id;
    dto.ignoredVariantIds = this.ignoredVariantIds;
    dto.categoryId = this.categoryId;
    dto.advancedFilter = this.advancedFilter;
    dto.name = this.name;
    dto.itemCount = this.itemCount;
    dto.primarySort = this.primarySort;
    dto.secondarySort = this.secondarySort;
    dto.usePurpose = this.usePurpose;
    dto.productType = this.productType;
    dto.variantType = this.variantType;
    dto.classification = this.classification;
    dto.minPrice = this.minPrice;
    dto.maxPrice = this.maxPrice;
    dto.minSecondaryPrice = this.minSecondaryPrice;
    dto.maxSecondaryPrice = this.maxSecondaryPrice;
    // replace whites space , white space with ,
    const fixDelimiter = (value: string) => value?.replace(/\s*,\s*/g, ',');
    dto.packagedQuantities = fixDelimiter(this.packagedQuantities);
    dto.unitSizes = fixDelimiter(this.unitSizes);
    dto.productName = fixDelimiter(this.productName);
    dto.brandName = fixDelimiter(this.brandName);
    dto.strain = fixDelimiter(this.strain);
    dto.searchTerm = fixDelimiter(this.searchTerm);
    dto.minInventory = this.minInventory;
    dto.maxInventory = this.maxInventory;
    dto.pricingCodes = this.pricingCodes;
    dto.activeSale = this.activeSale;
    dto.requirePricingTier = this.requirePricingTier;
    dto.labelIds = this.labelIds;
    dto.badgeIds = this.badgeIds;
    dto.presentCannabinoids = this.presentCannabinoids;
    dto.topTerpene = this.topTerpene;
    dto.minTotalTerpene = this.minTotalTerpene;
    dto.maxTotalTerpene = this.maxTotalTerpene;
    dto.presentTerpenes = this.presentTerpenes;
    Object.values(PrimaryCannabinoid)?.forEach(cannabinoid => {
      dto[`min${cannabinoid}`] = this[`min${cannabinoid}`];
      dto[`max${cannabinoid}`] = this[`max${cannabinoid}`];
    });
    Object.values(SecondaryCannabinoid)?.forEach(secondaryCannabinoid => {
      dto[`min${secondaryCannabinoid}`] = this[`min${secondaryCannabinoid}`];
      dto[`max${secondaryCannabinoid}`] = this[`max${secondaryCannabinoid}`];
    });
    Object.values(Terpene)?.forEach(terpene => {
      const terperneKey = StringUtils.toPascalCase(terpene);
      dto[`min${terperneKey}`] = this[`min${terperneKey}`];
      dto[`max${terperneKey}`] = this[`max${terperneKey}`];
    });
    return dto;
  }

  getAdditionalColumns(): string {
    let count = 0;
    if (this.activeSale) { count++; }
    if (this.topTerpene) { count++; }
    if (this.minTotalTerpene) { count++; }
    if (this.maxTotalTerpene) { count++; }
    if (this.minInventory) { count++; }
    if (this.maxInventory) { count++; }
    if (this.minPrice) { count++; }
    if (this.maxPrice) { count++; }

    switch (count) {
      case 0:
        return '-';
      default:
        return `+${count}`;
    }
  }

  getStandardizedCategoryName(): string {
    return this.category?.name?.trim()?.toLowerCase();
  }

  lookAheadDisabled(): boolean {
    return false;
  }

  lookAheadDisabledMessage(): string {
    return '';
  }

  matchesVariant(variant: Variant): boolean {
    if (exists(this.productType) && variant.productType !== this.productType) {
      return false;
    }

    if (exists(this.variantType) && variant.variantType !== this.variantType) {
        return false;
    }

    if (exists(this.strain) && variant.strain !== this.strain) {
      return false;
    }

    if (exists(this.classification) && variant.classification !== this.classification) {
      return false;
    }

    if (exists(this.searchTerm) && variant.name?.includes(this.searchTerm)) {
      return false;
    }

    if (exists(this.searchTerm) && variant.description?.includes(this.searchTerm)) {
      return false;
    }

    Object.values(PrimaryCannabinoid).forEach(primaryCannabinoid => {
      if (
        exists(this[`min${primaryCannabinoid}`])
        && variant.getNumericCannabinoidOrTerpene(primaryCannabinoid) < this[`min${primaryCannabinoid}`]
      ) {
        return false;
      }

      if (
        exists(this[`max${primaryCannabinoid}`])
        && variant.getNumericCannabinoidOrTerpene(primaryCannabinoid) < this[`max${primaryCannabinoid}`]
      ) {
        return false;
      }
    });
  }

  isCurated(): boolean {
    return this.locationId === -1;
  }

  matchesDisplayAttributeGroupDetails(groupDetails: DisplayAttributeGroupDetails): boolean {
    let cannabinoidMatch = true;

    const secondaryCannabinoids = Object.values(SecondaryCannabinoid);
    secondaryCannabinoids?.forEach(sc => {
      const min = `min${sc}`;
      const max = `max${sc}`;
      if (exists(this[min])
        && groupDetails?.getNumericValueOfCannabinoidOrTerpene(min as keyof DisplayAttributeGroupDetails) < this[min]
      ) {
        cannabinoidMatch = false;
      }
      if (exists(this[max])
        && groupDetails?.getNumericValueOfCannabinoidOrTerpene(max as keyof DisplayAttributeGroupDetails) > this[max]
      ) {
        cannabinoidMatch = false;
      }
    });

    if (!cannabinoidMatch) {
      return false;
    }

    let terpeneMatch = true;

    if (exists(this.topTerpene) && groupDetails?.topTerpene !== this.topTerpene) {
      return false;
    }
    if (exists(this.minTotalTerpene)
      && groupDetails?.getNumericValueOfCannabinoidOrTerpene('minTotalTerpene') < this.minTotalTerpene
    ) {
      return false;
    }

    if (exists(this.maxTotalTerpene)
      && groupDetails?.getNumericValueOfCannabinoidOrTerpene('maxTotalTerpene') > this.maxTotalTerpene
    ) {
      return false;
    }

    const terpenes = Object.values(Terpene);
    terpenes?.forEach(t => {
      const terpeneNoSpaces = t?.toString()?.stripWhiteSpace();
      const min = `min${terpeneNoSpaces}`;
      const max = `max${terpeneNoSpaces}`;
      if ((exists(this[min]) || exists(this[max])) && !groupDetails?.presentTerpenes?.contains(t)) {
        terpeneMatch = false;
      }
    });
    return terpeneMatch;
  }

    getUniqueIdentifier(): string {
      let uniqueId = `${this.id}
      -${this.activeSale}
      -${this.classification}
      -${this.ignoredVariantIds?.sort()?.join(',')}
      -${this.locationId}
      -${this.topTerpene}
      -${this.minTotalTerpene}
      -${this.maxTotalTerpene}
      -${this.minInventory}
      -${this.maxInventory}
      -${this.minPrice}
      -${this.maxPrice}
      -${this.minSecondaryPrice}
      -${this.maxSecondaryPrice}
      -${this.name}
      -${this.productType}
      -${this.searchTerm}
      -${this.strain}
      -${this.variantType}
      -${this.advancedFilter}
      -${this.hidden}
      -${this.lastModified}
      -${this.lastUpdated}
      -${this.itemCount}
      -${this.primarySort}
      -${this.secondarySort}
      -${this.usePurpose}
      -${this.packagedQuantities}
      -${this.unitSizes}
      -${this.productName}
      -${this.brandName}
      -${this.pricingCodes}
      -${this.requirePricingTier}
      -${this.isCompanySmartFilter}
      -${this.isCuratedSmartFilter}
      -${this.labelIds?.sort()?.join(',')}
      -${this.badgeIds?.sort()?.join(',')}
      -${this.presentCannabinoids?.sort()?.join(',')}
      -${this.presentTerpenes?.sort()?.join(',')}`;
      Object.values(PrimaryCannabinoid)?.forEach(cannabinoid => {
        uniqueId += `-${this[`min${cannabinoid}`]}`;
        uniqueId += `-${this[`max${cannabinoid}`]}`;
      });
      Object.values(SecondaryCannabinoid)?.forEach(secondaryCannabinoid => {
        uniqueId += `-${this[`min${secondaryCannabinoid}`]}`;
        uniqueId += `-${this[`max${secondaryCannabinoid}`]}`;
      });
      Object.values(Terpene)?.forEach(terpene => {
        const pascalCaseTerpene = StringUtils.toPascalCase(terpene);
        uniqueId += `-${this[`min${pascalCaseTerpene}`]}`;
        uniqueId += `-${this[`max${pascalCaseTerpene}`]}`;
      });
      return uniqueId;
    }

}
