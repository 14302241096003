import { Deserializable } from '../models/protocols/deserializable';
import { Cachable } from '../models/protocols/cachable';
import { DateUtils } from './date-utils';
import { TypeDefinition } from './type-definition';
import { ProvinceType } from './province-type';
import { VariantLookupTypeDefinition } from './variant-lookup-type-definition';
import { SectionLayoutType } from './section-layout-type';
import { OverflowStateType } from './overflow-state-type';
import { MenuTypeDefinition } from './menu-type-definition';
import { AssetLibraryType } from './asset-library-type';
import { DefaultPrintSizeType } from './default-print-size-type';
import { DefaultDigitalSizeType } from './default-digital-size-type';
import { CannabisUnitOfMeasureType } from './cannabis-unit-of-measure-type';
import { SectionTypeDefinition } from './section-type-definition';
import { SectionSortType } from './section-sort-type';
import { OrientationType } from './orientation-type';
import { ProductTypeDefinition } from './product-type-definition';
import { VariantTypeDefinition } from './variant-type-definition';
import { InventoryProviderType } from './inventory-provider-type';
import { VariantPropertyType } from './variant-property-type';
import { UnitOfMeasureType } from './unit-of-measure-type';
import { StrainTypeType } from './strain-type-type';
import { MenuSubtypeDefinition } from './menu-subtype-definition';
import { MenuLabelType } from './menu-label-type';
import { CardTypeDefinition } from './card-type-definition';
import { SaleLabelFormatType } from './sale-label-format-type';
import { SectionColumnConfigKeyType } from './section-column-config-key-type';
import { SectionColumnConfigDefaultStateType } from './section-column-config-default-state-type';
import { SectionColumnConfigDataValueType } from './section-column-config-data-value-type';
import { FontStyleType } from './font-style-type';
import { SyncTypeType } from './sync-type-type';
import { SyncJobStatusType } from './sync-job-status-type';
import { CannabinoidDisplayTypeDefinition } from './cannabinoid-display-type-definition';
import { UsePurposeType } from './use-purpose-type';
import { DefaultPrintCardSizeType } from './default-print-card-size-type';
import { DefaultPrintCardPaperSizeType } from './default-print-card-paper-size-type';
import { PrintCardPropertyDefinition } from './print-card-property-definition';
import { SmartFilterSystemIdentifiersTypeDefinition } from './smart-filter-system-identifiers-type-definition';
import { SortUtils } from './sort-utils';
import { TerpeneTypeDefinition } from './terpene-type-definition';
import { SecondaryCannabinoidsTypeDefinition } from './secondary-cannabinoids-type-definition';
import { DefaultPrintLabelPaperSizeType } from './default-print-label-paper-size-type';
import { DefaultPrintLabelSizeType } from './default-print-label-size-type';
import { UniversalVariantStatusTypeDefinition } from './universal-variant-status-type-definition';
import { TerpeneUnitOfMeasureType } from './terpene-unit-of-measure-type';
import { IndividualSectionColumnConfigKeyType } from './individual-section-column-config-key-type';

export class EnumTypes implements Deserializable, Cachable {

  public assetLibraryType: AssetLibraryType[];
  public cannabinoidDisplayType: CannabinoidDisplayTypeDefinition[];
  public cannabisUnitOfMeasure: CannabisUnitOfMeasureType[];
  public comboMenuCardType: CardTypeDefinition[];
  public companyRole: TypeDefinition[];
  public country: TypeDefinition[];
  public defaultPrintCardPaperSizes: DefaultPrintCardPaperSizeType[];
  public defaultPrintCardSizes: DefaultPrintCardSizeType[];
  public defaultPrintLabelPaperSizes: DefaultPrintLabelPaperSizeType[];
  public defaultPrintLabelSizes: DefaultPrintLabelSizeType[];
  public defaultPrintSizes: DefaultPrintSizeType[];
  public defaultDigitalSizes: DefaultDigitalSizeType[];
  public featuredCategoryMenuCardType: TypeDefinition[];
  public fontStyle: FontStyleType[];
  public groupDetailAppearance: TypeDefinition[];
  public groupDetailAromaFlavor: TypeDefinition[];
  public groupDetailEffect: TypeDefinition[];
  public groupDetailEnergyScore: TypeDefinition[];
  public groupDetailGrowingCondition: TypeDefinition[];
  public groupDetailHelpsWith: TypeDefinition[];
  public individualSectionColumnConfigKey: IndividualSectionColumnConfigKeyType[];
  public inventoryProvider: InventoryProviderType[];
  public menuLabel: MenuLabelType[];
  public menuPreviewJobStatus: TypeDefinition[];
  public menuStyleObject: TypeDefinition[];
  public menuSubType: MenuSubtypeDefinition[];
  public menuType: MenuTypeDefinition[];
  public optionScale: TypeDefinition[];
  public orientation: OrientationType[];
  public overflowState: OverflowStateType[];
  public priceFormat: TypeDefinition[];
  public printCardProperty: PrintCardPropertyDefinition[];
  public printFooterLayout: TypeDefinition[];
  public printHeaderLayout: TypeDefinition[];
  public productType: ProductTypeDefinition[];
  public promotionCondition: TypeDefinition[];
  public promotionDiscount: TypeDefinition[];
  public promotionPeriod: TypeDefinition[];
  public province: Map<string, ProvinceType[]>;
  public saleLabelFormat: SaleLabelFormatType[];
  public secondaryCannabinoids: SecondaryCannabinoidsTypeDefinition[];
  public sectionColumnConfigDataValue: SectionColumnConfigDataValueType[];
  public sectionColumnConfigDefaultState: SectionColumnConfigDefaultStateType[];
  public sectionColumnConfigKey: SectionColumnConfigKeyType[];
  public sectionLayoutType: SectionLayoutType[];
  public sectionSortOption: SectionSortType[];
  public sectionType: SectionTypeDefinition[];
  public sizeUnit: TypeDefinition[];
  public smartFilterSystemIdentifiers: SmartFilterSystemIdentifiersTypeDefinition[];
  public strainType: StrainTypeType[];
  public syncJobStatus: SyncJobStatusType[];
  public syncType: SyncTypeType[];
  public terpenes: TerpeneTypeDefinition[];
  public terpeneUnitOfMeasure: TerpeneUnitOfMeasureType[];
  public timezone: Map<string, TypeDefinition[]>;
  public unitOfMeasure: UnitOfMeasureType[];
  public universalVariantStatus: UniversalVariantStatusTypeDefinition[];
  public usePurpose: UsePurposeType[];
  public variantLookupType: VariantLookupTypeDefinition[];
  public variantProperty: VariantPropertyType[];
  public variantType: Map<string, VariantTypeDefinition[]>;
  // Cache
  public cachedTime: number;
  // @ts-ignore
  public versionString = require('package.json')?.version;

  static getCacheKey(): string {
    return new EnumTypes().cacheKey();
  }

  onDeserialize() {
    const Deserialize = window.injector.Deserialize;
    this.assetLibraryType = Deserialize.arrayOf(AssetLibraryType, this.assetLibraryType);
    this.cannabinoidDisplayType = Deserialize.arrayOf(TypeDefinition, this.cannabinoidDisplayType);
    this.cannabisUnitOfMeasure = Deserialize.arrayOf(CannabisUnitOfMeasureType, this.cannabisUnitOfMeasure);
    this.comboMenuCardType = Deserialize.arrayOf(CardTypeDefinition, this.comboMenuCardType);
    this.companyRole = Deserialize.arrayOf(TypeDefinition, this.companyRole);
    this.country = Deserialize.arrayOf(TypeDefinition, this.country);
    this.defaultPrintCardPaperSizes =
      Deserialize?.arrayOf(DefaultPrintCardPaperSizeType, this.defaultPrintCardPaperSizes);
    this.defaultPrintCardSizes = Deserialize?.arrayOf(DefaultPrintCardSizeType, this.defaultPrintCardSizes);
    this.defaultPrintLabelPaperSizes =
      Deserialize?.arrayOf(DefaultPrintLabelPaperSizeType, this.defaultPrintLabelPaperSizes);
    this.defaultPrintLabelSizes = Deserialize?.arrayOf(DefaultPrintLabelSizeType, this.defaultPrintLabelSizes);
    this.defaultPrintSizes = Deserialize.arrayOf(DefaultPrintSizeType, this.defaultPrintSizes);
    this.defaultDigitalSizes = Deserialize.arrayOf(DefaultDigitalSizeType, this.defaultDigitalSizes);
    this.featuredCategoryMenuCardType = Deserialize.arrayOf(TypeDefinition, this.featuredCategoryMenuCardType);
    this.fontStyle = Deserialize.arrayOf(FontStyleType, this.fontStyle);
    this.groupDetailAppearance = Deserialize.arrayOf(TypeDefinition, this.groupDetailAppearance);
    this.groupDetailAromaFlavor = Deserialize.arrayOf(TypeDefinition, this.groupDetailAromaFlavor);
    this.groupDetailEffect = Deserialize.arrayOf(TypeDefinition, this.groupDetailEffect);
    this.groupDetailEnergyScore = Deserialize.arrayOf(TypeDefinition, this.groupDetailEnergyScore);
    this.groupDetailGrowingCondition = Deserialize.arrayOf(TypeDefinition, this.groupDetailGrowingCondition);
    this.groupDetailHelpsWith = Deserialize.arrayOf(TypeDefinition, this.groupDetailHelpsWith);
    this.individualSectionColumnConfigKey = Deserialize.arrayOf(
      IndividualSectionColumnConfigKeyType,
      this.individualSectionColumnConfigKey
    );
    this.inventoryProvider = Deserialize.arrayOf(InventoryProviderType, this.inventoryProvider);
    this.menuLabel = Deserialize.arrayOf(MenuLabelType, this.menuLabel);
    this.menuPreviewJobStatus = Deserialize.arrayOf(TypeDefinition, this.menuPreviewJobStatus);
    this.menuStyleObject = Deserialize.arrayOf(TypeDefinition, this.menuStyleObject);
    this.menuType = Deserialize.arrayOf(MenuTypeDefinition, this.menuType);
    this.optionScale = Deserialize.arrayOf(TypeDefinition, this.optionScale);
    this.orientation = Deserialize.arrayOf(OrientationType, this.orientation);
    this.overflowState = Deserialize.arrayOf(OverflowStateType, this.overflowState);
    this.priceFormat = Deserialize.arrayOf(TypeDefinition, this.priceFormat);
    this.printCardProperty = Deserialize.arrayOf(PrintCardPropertyDefinition, this.printCardProperty);
    this.printFooterLayout = Deserialize.arrayOf(TypeDefinition, this.printFooterLayout);
    this.printHeaderLayout = Deserialize.arrayOf(TypeDefinition, this.printHeaderLayout);
    this.productType = Deserialize.arrayOf(ProductTypeDefinition, this.productType);
    this.promotionCondition = Deserialize.arrayOf(TypeDefinition, this.promotionCondition);
    this.promotionDiscount = Deserialize.arrayOf(TypeDefinition, this.promotionDiscount);
    this.promotionPeriod = Deserialize.arrayOf(TypeDefinition, this.promotionPeriod);
    this.province = Deserialize.typedArrayMapOf(ProvinceType, this.province);
    this.province?.forEach((value) => value?.sort(SortUtils.sortTypeDefinitionsByName));
    this.saleLabelFormat = Deserialize.arrayOf(SaleLabelFormatType, this.saleLabelFormat);
    this.secondaryCannabinoids = Deserialize.arrayOf(SecondaryCannabinoidsTypeDefinition, this.secondaryCannabinoids);
    this.sectionColumnConfigDataValue =
      Deserialize.arrayOf(SectionColumnConfigDataValueType, this.sectionColumnConfigDataValue);
    this.sectionColumnConfigDefaultState =
      Deserialize.arrayOf(SectionColumnConfigDefaultStateType, this.sectionColumnConfigDefaultState);
    this.sectionColumnConfigKey = Deserialize.arrayOf(SectionColumnConfigKeyType, this.sectionColumnConfigKey);
    this.sectionSortOption = Deserialize.arrayOf(SectionSortType, this.sectionSortOption);
    this.sectionLayoutType = Deserialize.arrayOf(SectionLayoutType, this.sectionLayoutType);
    this.sectionType = Deserialize.arrayOf(SectionTypeDefinition, this.sectionType);
    this.sizeUnit = Deserialize.arrayOf(TypeDefinition, this.sizeUnit);
    this.smartFilterSystemIdentifiers = Deserialize.arrayOf(
      SmartFilterSystemIdentifiersTypeDefinition,
      this.smartFilterSystemIdentifiers
    );
    this.strainType = Deserialize.arrayOf(StrainTypeType, this.strainType);
    this.syncJobStatus = Deserialize.arrayOf(SyncJobStatusType, this.syncJobStatus);
    this.syncType = Deserialize.arrayOf(SyncTypeType, this.syncType);
    this.menuSubType = Deserialize.arrayOf(MenuSubtypeDefinition, this.menuSubType);
    this.terpenes = Deserialize.arrayOf(TerpeneTypeDefinition, this.terpenes);
    this.terpeneUnitOfMeasure = Deserialize.arrayOf(TerpeneUnitOfMeasureType, this.terpeneUnitOfMeasure);
    this.timezone = Deserialize.typedArrayMapOf(TypeDefinition, this.timezone);
    this.unitOfMeasure = Deserialize.arrayOf(UnitOfMeasureType, this.unitOfMeasure);
    this.universalVariantStatus = Deserialize.arrayOf(
      UniversalVariantStatusTypeDefinition,
      this.universalVariantStatus
    );
    this.usePurpose = Deserialize?.arrayOf(UsePurposeType, this.usePurpose);
    this.variantLookupType = Deserialize.arrayOf(VariantLookupTypeDefinition, this.variantLookupType);
    this.variantProperty = Deserialize.arrayOf(VariantPropertyType, this.variantProperty);
    this.variantType = Deserialize.typedArrayMapOf(VariantTypeDefinition, this.variantType);
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneWeek();
  }

  cacheKey(): string {
    return `BudSense-Types-${this.versionString}`;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

}
