import { PrimaryCannabinoid } from '../shared/primary-cannabinoid.enum';
import { SecondaryCannabinoid } from '../shared/secondary-cannabinoid.enum';
import { Terpene } from '../shared/terpene.enum';
import { EnumUtils } from 'src/app/utils/enum-utils';
import { StringUtils } from '../../../utils/string-utils';

/** The order of these keys determines the sort order of the columns */
export enum IndividualSectionColumnConfigProductInfoKey {
  Asset = 'Asset',
  Badges = 'Badges',
  Brand = 'Brand',
  StrainType = 'ClassificationType', // Underlying value must remain ClassificationType for backwards compatibility
  Quantity = 'Quantity',
  Size = 'Size',
  QuantityAndSize = 'QuantityAndSize',
  Stock = 'Stock',
  Price = 'Price',
  SecondaryPrice = 'SecondaryPrice',
}

export const IndividualSectionColumnConfigPrimaryCannabinoidKey = EnumUtils.createEnum(
  Object.values(PrimaryCannabinoid)
);
export const IndividualSectionColumnConfigSecondaryCannabinoidKey = EnumUtils.createEnum(
  Object.values(SecondaryCannabinoid)
);

export type IndividualSectionColumnConfigCannabinoidKey =
  keyof typeof IndividualSectionColumnConfigPrimaryCannabinoidKey
  | keyof typeof IndividualSectionColumnConfigSecondaryCannabinoidKey;

export enum IndividualSectionColumnConfigCollectiveTerpeneKey {
  TopTerpene = 'TopTerpene',
  TotalTerpene = 'TotalTerpene',
}

export const IndividualSectionColumnConfigIndividualTerpeneKey = EnumUtils.createEnum(
  Object.values(Terpene)?.map(t => StringUtils.toPascalCase(t.toString()))
);

export type IndividualSectionColumnConfigTerpeneKey = IndividualSectionColumnConfigCollectiveTerpeneKey
  | keyof typeof IndividualSectionColumnConfigIndividualTerpeneKey;

export type IndividualSectionColumnConfigKey = IndividualSectionColumnConfigProductInfoKey
  | IndividualSectionColumnConfigCannabinoidKey
  | IndividualSectionColumnConfigTerpeneKey;
