import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnOptionsFormViewModel } from './column-options-form-view-model';
import { BaseComponent } from '../../../../../../../models/base/base-component';
import { ViewModelConnector } from '@mobilefirstdev/base-angular';
import { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import { SectionLayoutType } from '../../../../../../../utils/section-layout-type';
import { ColumnChanges } from '../../column-options-modal-view-model';
import { combineLatest } from 'rxjs';
import { IndividualSectionColumnConfigKey } from '../../../../../../../models/enum/dto/individual-section-column-config-key';

@Component({
  selector:'app-column-options-form',
  templateUrl:'./column-options-form.component.html',
  styleUrls: ['./column-options-form.component.scss'],
  providers: [ColumnOptionsFormViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnOptionsFormComponent extends BaseComponent  {

  constructor(
    public viewModel: ColumnOptionsFormViewModel
  ) {
    super();
  }

  @Input() @ViewModelConnector('connectToFormColumnConfig') formColumnConfig: SectionColumnConfig;
  @Input() @ViewModelConnector('connectToColumnSectionKey') columnSectionKey: IndividualSectionColumnConfigKey;
  @Input() @ViewModelConnector('connectToSectionLayoutType') sectionLayoutType: SectionLayoutType;
  @Input() defaultColumnName: string;
  @Input() formMergeKey: string = '';
  @Output() columnChanges: EventEmitter<ColumnChanges> = new EventEmitter<ColumnChanges>();

  override setupBindings(): void {
    const changesHandler = combineLatest([
      this.viewModel.columnSectionKey$,
      this.viewModel.changes$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([columnSectionKey, changes]) => {
        this.columnChanges.emit({
          key: columnSectionKey,
          defaultState: changes?.defaultState,
          columnWidth: changes?.columnWidth
        });
      }
    });
  }

}
