import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { ColumnOptionsModalViewModel } from './column-options-modal-view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { map, take } from 'rxjs/operators';
import { BaseModalComponent } from '../../../../../models/base/base-modal.component';
import { TabBarItem } from '../../../../../models/shared/stylesheet/tab-bar-item';
import { SectionBlueprint } from '../../../../../models/menu/dto/section-blueprint';
import { combineLatest, of } from 'rxjs';
import { ReactiveFormMergeGroupsComponent } from '@mobilefirstdev/reactive-form';
import { TabBarComponent } from '../../../../shared/components/tab-bar/tab-bar.component';
import { CannabinoidColumnOptionsFormComponent } from './components/cannabinoid-column-options-form/cannabinoid-column-options-form.component';
import { SingleColumnOptionsFormComponent } from './components/single-column-options-form/single-column-options-form.component';
import { TerpeneColumnOptionsFormComponent } from './components/terpene-column-options-form/terpene-column-options-form.component';

@Component({
  selector: 'app-column-options-modal',
  templateUrl: './column-options-modal.component.html',
  styleUrls: ['./column-options-modal.component.scss'],
  providers: [ColumnOptionsModalViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnOptionsModalComponent extends BaseModalComponent {

  constructor(
    public viewModel: ColumnOptionsModalViewModel,
    public activeModal: NgbActiveModal
  ) {
    super(activeModal);
  }

  @ViewChild('contentContainer') tabContainer: ElementRef<HTMLDivElement>;
  @ViewChild('tabController') tabController: TabBarComponent;
  @ViewChild('columnOptionsFormGroup') columnOptionsFormGroup: ReactiveFormMergeGroupsComponent;

  public canChangeTabsErrorMsg$ = of('Please correct the invalid data before changing tabs.');

  private tabContainerRO: ResizeObserver;

  public tabs$ = this.viewModel.tabNames$.pipe(
    map(tabNames => {
      const tabs = [];
      tabNames?.forEach(tabName => {
        switch (tabName) {
          case 'Cannabinoids':
            const cannabinoidsTabBarItem = new TabBarItem(
              CannabinoidColumnOptionsFormComponent,
              tabName,
              '',
              false
            );
            tabs.push(cannabinoidsTabBarItem);
            break;
          case 'Terpenes':
            const terpenesTabBarItem = new TabBarItem(
              TerpeneColumnOptionsFormComponent,
              tabName,
              '',
              false
            );
            tabs.push(terpenesTabBarItem);
            break;
          default:
            const tabBarItem = new TabBarItem(
              SingleColumnOptionsFormComponent,
              tabName,
              '',
              false
            );
            tabs.push(tabBarItem);
            break;
        }
      });
      return tabs;
    })
  );

  override setupBindings(): void {
    this.observeTabContainer();
  }

  setSectionBlueprint(sectionBlueprint: SectionBlueprint) {
    this.viewModel.connectToSectionBlueprint(sectionBlueprint);
  }

  setPreviousTab(index: number) {
    this.viewModel.connectToPreviouslySelectedTabIndex(index);
  }

  setCurrentTab(index: number) {
    this.viewModel.connectToCurrentlySelectedTabIndex(index);
  }

  checkForChanges() {
    combineLatest([this.viewModel?.unsavedChangesInTab$, this.viewModel?.formIsValid$]).pipe(
      take(1),
      map(([unsavedChanges, formIsValid]) => {
        if (unsavedChanges && formIsValid) {
          this.columnOptionsFormGroup.submitForms(true);
        }
      })
    ).subscribe();
  }

  private observeTabContainer(): void {
    this.tabContainerRO = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.calculateBottomButtonContainerPosition(entry.contentRect.height);
      }
    });
    this.tabContainerRO.observe(this.tabContainer.nativeElement);
  }

  private calculateBottomButtonContainerPosition(contentHeight: number): void {
    const dialogRect = document.getElementsByClassName('modal-dialog').item(0)?.getBoundingClientRect();
    const headerRect = document.getElementsByClassName('sticky-header-container').item(0)?.getBoundingClientRect();
    const footerRect = document.getElementsByClassName('sticky-footer-container').item(0)?.getBoundingClientRect();
    const heightUntilScrollableContent = (dialogRect?.height - headerRect?.height) - footerRect?.height;
    const position = contentHeight > heightUntilScrollableContent ? 'sticky' : 'absolute';
    this.viewModel.connectToBottomButtonPosition(position);
  }

}
