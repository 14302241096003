import { Injectable } from '@angular/core';
import { BaseSmartFilterFormViewModel } from '../base-smart-filter-form-view-model';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PrimaryCannabinoid } from '../../../../../models/enum/shared/primary-cannabinoid.enum';
import { TypeDefinition } from '../../../../../utils/type-definition';

@Injectable()
export class CannabinoidAddEditSmartFilterFormViewModel extends BaseSmartFilterFormViewModel {

  public primaryCannabinoids$ = of(Object.values(PrimaryCannabinoid));
  public secondaryCannabinoidDropdowns$ = window.types.secondaryCannabinoids$;
  public cannabinoidDropdowns$ = this.secondaryCannabinoidDropdowns$.pipe(
    map(secondaryCannabinoidDropdowns => {
      return [
        new TypeDefinition(PrimaryCannabinoid.THC, PrimaryCannabinoid.THC),
        new TypeDefinition(PrimaryCannabinoid.CBD, PrimaryCannabinoid.CBD),
        ...(secondaryCannabinoidDropdowns || [])
      ];
    })
  );
  public secondaryCannabinoids$: Observable<string[]> = this.secondaryCannabinoidDropdowns$.pipe(
    map(secondaryCannabinoidTypes => secondaryCannabinoidTypes?.map(sct => sct?.getSelectionValue()))
  );

}
