import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { DateUtils } from '../../../utils/date-utils';
import { SectionColumnConfig } from './section-column-config';
import { SectionLayout, SectionLayoutType } from '../../../utils/section-layout-type';
import { SectionMetadata } from './section-metadata';
import { SectionSortOption } from '../../../utils/section-sort-type';
import { SectionType } from '../../../utils/section-type-definition';
import { SectionBlueprintCategory } from './section-blueprint-category';
import { Asset } from '../../image/dto/asset';
import { SectionColumnConfigKey } from '../../enum/dto/section-column-config-key';
import { IndividualSectionColumnConfigKey } from '../../enum/dto/individual-section-column-config-key';
import { IndividualSectionColumnConfigKeyType } from '../../../utils/individual-section-column-config-key-type';

export class SectionBlueprint implements Deserializable, UniquelyIdentifiable {

  public companyId: number;
  public id: string;
  public title: string;
  public subTitle: string;
  public autoUpdateGridColumns: boolean = false;
  public columnConfig: Map<IndividualSectionColumnConfigKey, SectionColumnConfig>;
  public dateCreated: number;
  public lastUpdated: number;
  public layoutType: SectionLayout;
  public metadata: SectionMetadata;
  public rowCount: number;
  public sorting: SectionSortOption;
  public secondarySorting: SectionSortOption;
  public sectionType: SectionType;
  public showZeroStockItems: boolean;
  public smartFilterIds: string[];
  public categoryId: string;
  public category: SectionBlueprintCategory;
  public asset: Asset;
  // Cache
  public cachedTime: number;

  constructor() {
  }

  static newSectionBlueprint(): SectionBlueprint {
    const sb = new SectionBlueprint();
    sb.title = '';
    sb.companyId = -1;
    sb.rowCount = 0;
    sb.sectionType = null;
    sb.layoutType = SectionLayout.List;
    sb.sorting = SectionSortOption.BrandAsc;
    sb.secondarySorting = SectionSortOption.THCAsc;
    return sb;
  }

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    if (!this.columnConfig) {
      this.columnConfig = new Map<SectionColumnConfigKey, SectionColumnConfig>();
    } else if (!(this.columnConfig instanceof Map)) {
      this.columnConfig = Deserialize?.mapOf(SectionColumnConfig, this.columnConfig);
    } else {
      this.columnConfig = new Map<IndividualSectionColumnConfigKey, SectionColumnConfig>(this.columnConfig);
    }
    IndividualSectionColumnConfigKeyType.getAllKeys()?.forEach(key => {
      if (!this.columnConfig?.get(key)) {
        this.columnConfig.set(key, new SectionColumnConfig());
      }
    });
    this.metadata = Deserialize?.instanceOf(SectionMetadata, this.metadata);
    this.smartFilterIds = Array.from(this.smartFilterIds || []);
    this.category = window?.injector?.Deserialize?.instanceOf(SectionBlueprintCategory, this.category);
    this.asset = window?.injector?.Deserialize?.instanceOf(Asset, this.asset);
    this.transformFalsyRowCountToNull();
  }

  private transformFalsyRowCountToNull() {
    if (!this.rowCount) this.rowCount = null;
  }

  static buildCacheKey(id: string): string {
    return `Section-Blueprint-${id}`;
  }

  cacheExpirySeconds(): number {
    return DateUtils.secondsInOneHour();
  }

  cacheKey(): string {
    return SectionBlueprint.buildCacheKey(this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestampInSeconds() > expiresAt;
  }

  isProductType(): boolean {
    return this.sectionType === SectionType.Product;
  }

  getUniqueIdentifier(): string {
    const id = this.id;
    const companyId = this.companyId;
    const titleId = this.title ?? '';
    const subtitleId = this.subTitle ?? '';
    const zeroStockId = `${this.showZeroStockItems}`;
    const sortingId = this.sorting ?? '';
    const sectionTypeId = this.sectionType ?? '';
    const layoutTypeId = this.layoutType ?? '';
    const columnConfigMap: string[] = [];
    this.columnConfig?.forEach((val, key) => {
      const columnConfig = val?.getUniqueIdentifier();
      columnConfigMap.push(`${key}-${columnConfig}`);
    });
    const metadataId = this.metadata?.getUniqueIdentifier() ?? '';
    const rowCount = `${this.rowCount}`;
    const smartFilterIds = this.smartFilterIds.sort().join(',') ?? '';
    const categoryId = this.categoryId;
    const assetId = this.asset?.getUniqueIdentifier() ?? '';
    return `${id}
            -${companyId}
            -${titleId}
            -${subtitleId}
            -${zeroStockId}
            -${sortingId}
            -${sectionTypeId}
            -${columnConfigMap.sort().join(',')}
            -${layoutTypeId}
            -${metadataId}
            -${rowCount}
            -${smartFilterIds}
            -${categoryId}
            -${assetId}`;
  }

  public hasSmartFilters(): boolean {
    return this.smartFilterIds?.length > 0;
  }

  public getLayoutType(): SectionLayoutType {
    return window?.types?.initTypeDefinition(SectionLayoutType, this.layoutType);
  }

  public isAnyGrid(): boolean {
    return this.getLayoutType()?.isAnyGrid();
  }

  addSmartFilters(ids: string[]) {
    this.smartFilterIds = (this.smartFilterIds || [])?.concat(ids)?.unique();
  }

  removeSmartFilter(id: string) {
    const index = this.smartFilterIds?.findIndex(smartFilterId => smartFilterId === id);
    if (index >= 0) {
      this.smartFilterIds?.splice(index, 1);
    }
  }

  /** returns self */
  translateIntoDTO(): SectionBlueprint {
    IndividualSectionColumnConfigKeyType.getAllKeys()?.forEach(key => {
      const columnConfig = this.columnConfig?.get(key) as SectionColumnConfig | null;
      this.columnConfig.set(key, columnConfig?.translateToDTO());
    });
    return this;
  }

}
