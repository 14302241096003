import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { SectionColumnConfigDefaultState } from 'src/app/utils/section-column-config-default-state-type';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import { SectionLayoutType } from '../../../../../../../utils/section-layout-type';
import { IndividualSectionColumnConfigKey, IndividualSectionColumnConfigProductInfoKey } from 'src/app/models/enum/dto/individual-section-column-config-key';

// local interface for keeping track of column changes
export interface ConfigChanges { defaultState: SectionColumnConfigDefaultState; columnWidth?: number }

@Injectable()
export class ColumnOptionsFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private _changes = new BehaviorSubject<ConfigChanges>(null);
  public changes$ = this._changes as Observable<ConfigChanges>;
  connectToChanges = (changes: ConfigChanges) => {
    this._changes.next(changes);
  };

  private _changedDefaultState = new BehaviorSubject<SectionColumnConfigDefaultState>(null);
  public changedDefaultState$ = this._changedDefaultState as Observable<SectionColumnConfigDefaultState>;
  connectToChangedDefaultState = (defaultState: SectionColumnConfigDefaultState) => {
    this._changedDefaultState.next(defaultState);
  };

  private _formColumnConfig = new BehaviorSubject<SectionColumnConfig>(null);
  public formColumnConfig$ = this._formColumnConfig as Observable<SectionColumnConfig>;
  connectToFormColumnConfig = (formColumnConfig: SectionColumnConfig) => {
    this._formColumnConfig.next(formColumnConfig);
  };

  private _columnSectionKey = new BehaviorSubject<IndividualSectionColumnConfigKey>(null);
  public columnSectionKey$ = this._columnSectionKey as Observable<IndividualSectionColumnConfigKey>;
  connectToColumnSectionKey = (columnSectionKey: IndividualSectionColumnConfigKey) => {
    this._columnSectionKey.next(columnSectionKey);
  };

  public inputDisabled$ = combineLatest([
    this.formColumnConfig$,
    this.changedDefaultState$
  ]).pipe(
    map(([formColumnConfig, changedDefaultState]) => {
      let defaultState = formColumnConfig?.defaultState;
      if (!!changedDefaultState) {
        defaultState = changedDefaultState;
      }
      return !defaultState ||
        defaultState === SectionColumnConfigDefaultState.Off ||
        defaultState === SectionColumnConfigDefaultState.Disabled;
    })
  );

  private _sectionLayoutType = new BehaviorSubject<SectionLayoutType>(null);
  public sectionLayoutType$ = this._sectionLayoutType as Observable<SectionLayoutType>;
  connectToSectionLayoutType = (sectionLayoutType: SectionLayoutType) => {
    this._sectionLayoutType.next(sectionLayoutType);
  };

  public hideTextOptions$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === IndividualSectionColumnConfigProductInfoKey.Asset
        || key === IndividualSectionColumnConfigProductInfoKey.Badges;
    })
  );

}
