import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SectionColumnConfig } from '../../../../../../../models/menu/dto/section-column-config';
import { SectionLayoutType } from '../../../../../../../utils/section-layout-type';
import { map, switchMap } from 'rxjs/operators';
import { ColumnOptionsModalViewModel } from '../../column-options-modal-view-model';
import { SectionColumnConfigDefaultState, SectionColumnConfigDefaultStateType } from '../../../../../../../utils/section-column-config-default-state-type';
import { InformationItem } from '../../../../../../../models/shared/information-item';
import { IndividualSectionColumnConfigCollectiveTerpeneKey, IndividualSectionColumnConfigIndividualTerpeneKey, IndividualSectionColumnConfigKey, IndividualSectionColumnConfigPrimaryCannabinoidKey, IndividualSectionColumnConfigProductInfoKey, IndividualSectionColumnConfigSecondaryCannabinoidKey } from '../../../../../../../models/enum/dto/individual-section-column-config-key';

type DefaultMapStates = [IndividualSectionColumnConfigKey, Observable<SectionColumnConfigDefaultStateType[]>];

@Injectable()
export class ColumnGeneralOptionsFormViewModel extends BaseViewModel {

  constructor() {
    super();
  }

  private noAutoSectionDefaultStateOptions$ = window.types.noAutoSectionColumnConfigDefaultStates$;
  private autoSectionDefaultStateOptions$ = window.types.autoSectionColumnConfigDefaultStates$;
  private disableSectionDefaultStateOptions$ = window.types.disabledSectionColumnConfigDefaultStates$;

  private selectableDefaultStateMap = new Map([
    ...Object.values(IndividualSectionColumnConfigProductInfoKey).map(key => {
      switch (key) {
        case IndividualSectionColumnConfigProductInfoKey.Asset:
        case IndividualSectionColumnConfigProductInfoKey.StrainType:
        case IndividualSectionColumnConfigProductInfoKey.Price:
        case IndividualSectionColumnConfigProductInfoKey.SecondaryPrice:
          return [key, this.noAutoSectionDefaultStateOptions$];
        default:
          return [key, this.autoSectionDefaultStateOptions$];
      }
    }),
    ...Object.values(IndividualSectionColumnConfigPrimaryCannabinoidKey).map(k => {
      return [k, this.autoSectionDefaultStateOptions$];
    }),
    ...Object.values(IndividualSectionColumnConfigSecondaryCannabinoidKey).map(k => {
      return [k, this.autoSectionDefaultStateOptions$];
    }),
    ...Object.values(IndividualSectionColumnConfigCollectiveTerpeneKey).map(k => {
      return [k, this.autoSectionDefaultStateOptions$];
    }),
    ...Object.values(IndividualSectionColumnConfigIndividualTerpeneKey).map(k => {
      return [k, this.autoSectionDefaultStateOptions$];
    }),
  ] as DefaultMapStates[]);

  private _formColumnConfig = new BehaviorSubject<SectionColumnConfig>(null);
  public formColumnConfig$ = this._formColumnConfig as Observable<SectionColumnConfig>;
  connectToFormColumnConfig = (formColumnConfig: SectionColumnConfig) => this._formColumnConfig.next(formColumnConfig);

  private _columnSectionKey = new BehaviorSubject<IndividualSectionColumnConfigKey>(null);
  public columnSectionKey$ = this._columnSectionKey as Observable<IndividualSectionColumnConfigKey>;
  connectToColumnSectionKey = (columnSectionKey: IndividualSectionColumnConfigKey) => {
    this._columnSectionKey.next(columnSectionKey);
  };

  public columnSectionKeyIsCannabinoidOrTerpene$ = this.columnSectionKey$.pipe(
    map(key => {
      const cannabinoidAndTerpeneKeys = [
        ...(Object.values(IndividualSectionColumnConfigPrimaryCannabinoidKey)?.map(key => key?.toString()) || []),
        ...(Object.values(IndividualSectionColumnConfigSecondaryCannabinoidKey)?.map(key => key?.toString()) || []),
        ...(Object.values(IndividualSectionColumnConfigIndividualTerpeneKey)?.map(key => key?.toString()) || [])
      ];
      return cannabinoidAndTerpeneKeys.includes(key);
})
  );

  private _sectionLayoutType = new BehaviorSubject<SectionLayoutType>(null);
  public sectionLayoutType$ = this._sectionLayoutType as Observable<SectionLayoutType>;
  connectToSectionLayoutType = (sectionLayoutType: SectionLayoutType) => {
    this._sectionLayoutType.next(sectionLayoutType);
  };

  private _inputDisabled = new BehaviorSubject<boolean>(false);
  public inputDisabled$ = this._inputDisabled as Observable<boolean>;
  connectToInputDisabled = (inputDisabled: boolean) => this._inputDisabled.next(inputDisabled);

  public columnConfigDefaultState$ = this.formColumnConfig$.pipe(
    map(columnConfig => columnConfig?.defaultState)
  );

  public sectionDefaultStateOptions$ = combineLatest([
    this.sectionLayoutType$,
    this.columnSectionKey$
  ]).pipe(
    switchMap(([sectionLayoutType, columnSectionKey]) => {
      if (ColumnOptionsModalViewModel.disabledForGridLayout(columnSectionKey, sectionLayoutType)) {
        return this.disableSectionDefaultStateOptions$;
      }
      return this.selectableDefaultStateMap?.get(columnSectionKey);
    }),
    map(defaultStateOptions => {
      const unknownDefaultStateOpt = new SectionColumnConfigDefaultStateType(
        SectionColumnConfigDefaultState.Unknown,
        'Select Default State'
      );
      return [...defaultStateOptions, unknownDefaultStateOpt];
    })
  );

  public tooltip$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$
  ]).pipe(
    map(([columnSectionKey, columnSectionKeyIsCannabinoidOrTerpene]) => {
      if (columnSectionKeyIsCannabinoidOrTerpene) {
        return 'Auto - only if 1 or more variants in section have cannabinoids and with a value of at least 1';
      }
      switch (columnSectionKey) {
        case IndividualSectionColumnConfigProductInfoKey.Asset:
          return 'Show product images in menu';
        case IndividualSectionColumnConfigProductInfoKey.Badges:
          return 'Auto - only appears when 1 or more variants in section have a badge associated';
        case IndividualSectionColumnConfigProductInfoKey.Brand:
          return 'Auto - only appears when 1 or more variants in section have a brand associated';
        case IndividualSectionColumnConfigProductInfoKey.StrainType:
          return 'Show product strain type in menu';
        case IndividualSectionColumnConfigProductInfoKey.Quantity:
          return 'Auto - only if 1 or more variants in section have QTY > 1. Will be disabled if menu is set to grid';
        case IndividualSectionColumnConfigProductInfoKey.Size:
          return 'Auto - only if 2 or more sizes exist on section variants';
        case IndividualSectionColumnConfigProductInfoKey.Price:
          return 'Show Price in menu';
        case IndividualSectionColumnConfigProductInfoKey.SecondaryPrice:
          return 'This column allows you to display a comparative price or display the price per unit of measure. ' +
            'Will be disabled if menu is set to grid.';
        default:
          return '';
      }
    })
  );

  public defaultStateInputDisabled$ = combineLatest([
    this.sectionLayoutType$,
    this.columnSectionKey$,
    this.formColumnConfig$
  ]).pipe(
    map(([layoutType, columnSectionKey, formColumnConfig]) => {
      return (layoutType?.isGrid() && columnSectionKey === IndividualSectionColumnConfigProductInfoKey.Price) ||
        formColumnConfig?.defaultState === SectionColumnConfigDefaultState.Disabled;
    })
  );

  public hideColumnName$ = this.columnSectionKey$.pipe(
    map(key => {
      return key === IndividualSectionColumnConfigProductInfoKey.Asset;
    })
  );

  public showColumnDataOptions$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$
  ]).pipe(
    map(([key, columnKeyIsCannabinoidOrTerpene]) => {
      return columnKeyIsCannabinoidOrTerpene
        || key === IndividualSectionColumnConfigProductInfoKey.Asset
        || key === IndividualSectionColumnConfigProductInfoKey.SecondaryPrice;
    })
  );

  public columnDataOptionsLabel$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$
  ]).pipe(
    map(([key, columnSectionKeyIsCannabinoidOrTerpene]) => {
      switch (true) {
        case key === IndividualSectionColumnConfigProductInfoKey.Asset:
          return 'Object Fit';
        case key === IndividualSectionColumnConfigProductInfoKey.SecondaryPrice:
          return 'Use Value';
        case key === IndividualSectionColumnConfigProductInfoKey.StrainType:
          return 'Data Layout';
        case columnSectionKeyIsCannabinoidOrTerpene:
          return 'Display Format';
      }
    })
  );

  public columnDataOptionsToolTipModalTitle$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$
  ]).pipe(
    map(([key, columnSectionKeyIsCannabinoidOrTerpene]) => {
      switch (true) {
        case key === IndividualSectionColumnConfigProductInfoKey.Asset:
          return 'Object Fit Information';
        case key === IndividualSectionColumnConfigProductInfoKey.SecondaryPrice:
          return 'Secondary Price Information';
        case key === IndividualSectionColumnConfigProductInfoKey.StrainType:
          return 'Strain Type Layout';
        case columnSectionKeyIsCannabinoidOrTerpene:
          return 'Display Format';
      }
    })
  );

  private dataValueDropdowns$ = combineLatest([
    window.types.assetColumnConfigDataValues$,
    window.types.secondaryPriceColumnConfigDataValues$,
    window.types.strainTypeColumnConfigDataValues$,
    window.types.displayFormatTypeColumnConfigDataValues$,
  ]);

  public columnDataOptionsDropdowns$ = combineLatest([
    this.columnSectionKey$,
    this.columnSectionKeyIsCannabinoidOrTerpene$,
    this.dataValueDropdowns$
  ]).pipe(
    map(([
      key,
      columnSectionKeyIsCannabinoidOrTerpene,
      [
        assetDropdowns,
        secondaryPriceDropdowns,
        strainTypeDropdowns,
        displayFormatDropdowns
      ]
    ]) => {
      switch (true) {
        case key === IndividualSectionColumnConfigProductInfoKey.Asset:
          return assetDropdowns;
        case key === IndividualSectionColumnConfigProductInfoKey.SecondaryPrice:
          return secondaryPriceDropdowns;
        case key === IndividualSectionColumnConfigProductInfoKey.StrainType:
          return strainTypeDropdowns;
        case columnSectionKeyIsCannabinoidOrTerpene:
          return displayFormatDropdowns;
      }
    })
  );

  public columnDataOptionsToolTipModalItems$ = this.columnDataOptionsDropdowns$.pipe(
    map(dropdowns => {
      return dropdowns?.map(m => new InformationItem(m?.name, m?.getSelectionInformationDescription()));
    })
  );

  public widthRequired$ = this.inputDisabled$.pipe(
    map(inputDisabled => !inputDisabled)
  );

}
