import { BaseModalViewModel } from 'src/app/models/base/base-modal-view-model';
import { Injectable } from '@angular/core';
import { SyncDomainModel } from '../../../../../../../../domainModels/sync-domain-model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyDomainModel } from '../../../../../../../../domainModels/company-domain-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SyncDataJob } from '../../../../../../../../models/automation/sync-data-job';

@Injectable()
export class ReviewSyncJobViewModel extends BaseModalViewModel {

  constructor(
    private syncDomainModel: SyncDomainModel,
    private companyDomainModel: CompanyDomainModel,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public company$ = combineLatest([
    this.companyDomainModel.companies$,
    this.syncDomainModel.companyId$,
  ]).pipe(
    map(([companies, companyId]) => {
      return companies?.find(c => c.id === companyId);
    })
  );

  public inventoryProvider$ = this.company$.pipe(
    map(company => company?.provider)
  );

  private _syncDataJob = new BehaviorSubject<SyncDataJob>(null);
  public syncDataJob$ = this._syncDataJob as Observable<SyncDataJob>;
  connectToSyncDataJob = (syncDataJob: SyncDataJob) => {
    this._syncDataJob.next(syncDataJob);
  };

  public syncDataJobName$ = this.syncDataJob$.pipe(
    map(syncDataJob => syncDataJob?.name)
  );

  public syncDataLocationNames$ = combineLatest([
    this.syncDataJob$,
    this.company$
  ]).pipe(
    map(([syncDataJob, company]) => {
      if (syncDataJob?.locationIds?.length === company?.locations?.length) {
        return ['All Locations'];
      }
      return company?.locations
        ?.filter(location => syncDataJob?.locationIds?.includes(location?.id))
        ?.map(location => location?.name);
    })
  );

  public syncDataLocationLengthIsOne$ = this.syncDataLocationNames$.pipe(
    map(syncDataLocationNames => syncDataLocationNames?.length === 1)
  );

  public syncDataTypes$ = this.syncDataJob$.pipe(
    map(syncDataJob => syncDataJob?.syncTypes)
  );

}
