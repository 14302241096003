import { Injectable } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { BaseModalViewModel } from '../../../../../models/base/base-modal-view-model';
import { SectionColumnConfigDefaultState } from '../../../../../utils/section-column-config-default-state-type';
import { ToastService } from '../../../../../services/toast-service';
import { SectionBlueprint } from '../../../../../models/menu/dto/section-blueprint';
import { SectionColumnConfig } from '../../../../../models/menu/dto/section-column-config';
import { SectionLayoutType } from '../../../../../utils/section-layout-type';
import { SortUtils } from '../../../../../utils/sort-utils';
import { MenuDomainModel } from '../../../../../domainModels/menu-domain-model';
import { SectionType } from '../../../../../utils/section-type-definition';
import { BsError } from '../../../../../models/shared/bs-error';
import { IndividualSectionColumnConfigKeyType } from '../../../../../utils/individual-section-column-config-key-type';
import { IndividualSectionColumnConfigKey, IndividualSectionColumnConfigProductInfoKey } from '../../../../../models/enum/dto/individual-section-column-config-key';

export interface ColumnChanges {
  key: IndividualSectionColumnConfigKey;
  defaultState: SectionColumnConfigDefaultState;
  columnWidth?: number;
}

export class DefaultStateChanges {

  key: IndividualSectionColumnConfigKey;
  defaultState: SectionColumnConfigDefaultState;

}

@Injectable()
export class ColumnOptionsModalViewModel extends BaseModalViewModel {

  constructor(
    private menuDomainModel: MenuDomainModel,
    private activeModal: NgbActiveModal,
    public toastService: ToastService,
    router: Router,
    ngbModal: NgbModal
  ) {
    super(router, ngbModal);
  }

  public mergeKey = 'column-options-form';

  private _formIsValid = new BehaviorSubject<boolean>(false);
  public formIsValid$ = this._formIsValid as Observable<boolean>;
  connectToFormatIsValid = (formIsValid: boolean) => this._formIsValid.next(formIsValid);

  private _unsavedChangesInTab = new BehaviorSubject<boolean>(false);
  public unsavedChangesInTab$ = this._unsavedChangesInTab as Observable<boolean>;
  connectToUnsavedChangesInTab = (unsavedChangesInTab: boolean) => this._unsavedChangesInTab.next(unsavedChangesInTab);

  private _unsavedChangesInModal = new BehaviorSubject<boolean>(false);
  public unsavedChangesInModal$ = this._unsavedChangesInModal as Observable<boolean>;

  private _sectionBlueprint = new BehaviorSubject<SectionBlueprint>(null);
  public sectionBlueprint$ = this._sectionBlueprint as Observable<SectionBlueprint>;
  connectToSectionBlueprint = (sectionBlueprint: SectionBlueprint) => {
    this._sectionBlueprint.next(sectionBlueprint);
  };

  public layoutType$ = this.sectionBlueprint$.pipe(map(blueprint => blueprint?.getLayoutType()));
  public columnConfig$ = this.sectionBlueprint$.pipe(map(blueprint => blueprint?.columnConfig));

  public message$ = of('By default, the product name column must be at least 25% and will expand ' +
    'to take up any additional available width.');

  private _columnConfigMap = new BehaviorSubject<Map<IndividualSectionColumnConfigKey, SectionColumnConfig>>(null);
  public columnConfigMap$ =
    this._columnConfigMap as Observable<Map<IndividualSectionColumnConfigKey, SectionColumnConfig>>;

  public columnConfigBeingManaged$ = this.columnConfig$.subscribeWhileAlive({
    owner: this,
    next: columnConfig => {
      const newConfigMap = new Map<IndividualSectionColumnConfigKey, SectionColumnConfig>();
      columnConfig?.forEach((config, key) => {
        newConfigMap.set(key, window?.injector?.Deserialize?.instanceOf(SectionColumnConfig, config));
      });
      this._columnConfigMap.next(newConfigMap);
    }
  });

  private _currentlySelectedTabIndex = new BehaviorSubject<number>(0);
  public currentlySelectedTabIndex$ = this._currentlySelectedTabIndex as Observable<number>;
  connectToCurrentlySelectedTabIndex = (id: number) => this._currentlySelectedTabIndex.next(id);

  private _previouslySelectedTabIndex = new BehaviorSubject<number>(0);
  public previouslySelectedTabIndex$ = this._previouslySelectedTabIndex as Observable<number>;
  connectToPreviouslySelectedTabIndex = (id: number) => this._previouslySelectedTabIndex.next(id);

  private _defaultStateChanges: BehaviorSubject<DefaultStateChanges> = new BehaviorSubject<DefaultStateChanges>(null);
  private defaultStateChanges$ = this._defaultStateChanges as Observable<DefaultStateChanges>;
  connectToColumnDefaultStateChange = (changes: DefaultStateChanges) => this._defaultStateChanges.next(changes);

  private _columnChanges: BehaviorSubject<ColumnChanges> = new BehaviorSubject<ColumnChanges>(null);
  private columnChanges$ = this._columnChanges as Observable<ColumnChanges>;
  connectToColumnChanges = (changes: ColumnChanges) => this._columnChanges.next(changes);

  private trackModalChanges = this.unsavedChangesInTab$.subscribe(unsavedChanges => {
    if (unsavedChanges) {
      this._unsavedChangesInModal.next(true);
    }
  });

  private _bottomButtonPosition = new BehaviorSubject<string>('absolute');
  public bottomButtonPosition$ = this._bottomButtonPosition.pipe(distinctUntilChanged());
  connectToBottomButtonPosition = (position: string) => this._bottomButtonPosition.next(position);

  public canChangeTabs$ = combineLatest([this.unsavedChangesInTab$, this.formIsValid$]).pipe(
    map(([hasChanges, formIsValid]) => {
      if (!hasChanges) {
        return true;
      } else {
        return formIsValid;
      }
    })
  );

  public columnsWidthTotal$ = combineLatest([
    this.columnConfigMap$,
    this.layoutType$,
    this.columnChanges$
  ]).pipe(
    map(([
      columnConfigMap,
      layoutType,
      columnChanges
    ]) => {
      let columnWidthTotal = 25;
      columnConfigMap?.forEach((columnConfig, key) => {
        let columnState = columnConfig?.defaultState;
        if (!!columnChanges && key === columnChanges.key) {
          columnState = columnChanges.defaultState;
        }
        if (!!columnState &&
          columnState !== SectionColumnConfigDefaultState.Disabled &&
          columnState !== SectionColumnConfigDefaultState.Off
        ) {
          let widthToBeAdded = columnConfig?.columnWidth || 10;
          if (!!columnChanges && key === columnChanges.key) {
            widthToBeAdded = columnChanges.columnWidth;
          }
          columnWidthTotal += widthToBeAdded;
        }
      });
      return columnWidthTotal;
    })
  );

  public columnsWidthTotalError$ = this.columnsWidthTotal$.pipe(
    map(columnsWidthTotal => columnsWidthTotal > 100)
  );

  public columnsWidthTotalErrorMessage$ = this.columnsWidthTotal$.pipe(
    map(columnsWidthTotal => {
      return `All enabled columns, including the name column (25%), add up to ${columnsWidthTotal}% ` +
        `of the available space. When all columns are present, the menu will not format properly.`;
    })
  );

  public sectionColumnConfigTabKeys$ = combineLatest([
    this.sectionBlueprint$,
    window?.types?.individualSectionColumnConfigKeys$,
  ]).pipe(
    map(([sectionBlueprint, keys]) => {
      const sectionType = sectionBlueprint?.sectionType;
      const sectionTypeNotReportNewProducts = sectionType !== SectionType.PrintReportNewProducts;
      const sectionTypeNotReportRestockedProducts = sectionType !== SectionType.PrintReportRestockedProducts;
      let keysToSort = keys;
      if (sectionTypeNotReportNewProducts && sectionTypeNotReportRestockedProducts) {
        keysToSort = keys?.filter(k => !k?.isStockKey());
      }
      return keysToSort?.sort(SortUtils.columnOptionIndividualKeySortAsc);
    })
  );

  public tabNames$ = combineLatest([
    this.sectionBlueprint$,
    this.sectionColumnConfigTabKeys$
  ]).pipe(
    map(([section, keys]) => {
      return keys?.map(key => {
        if (key.isCannabinoidKey()) return 'Cannabinoids';
        if (key.isTerpeneKey()) return 'Terpenes';
        return key.getSelectionTitle();
      }).unique();
    })
  );

  private columnSectionKeyType$ = combineLatest([
    this.tabNames$,
    this.sectionColumnConfigTabKeys$,
    this.currentlySelectedTabIndex$
  ]).pipe(
    map(([tabNames, keys, currentIndex]) => {
      const currentTabName = tabNames[currentIndex];
      switch (currentTabName) {
        case 'Cannabinoids':
        case 'Terpenes':
          return new IndividualSectionColumnConfigKeyType(currentTabName, currentTabName);
        default:
          return keys?.find(k => k?.getSelectionTitle() === currentTabName);
      }
    })
  );

  public columnSectionKey$ = this.columnSectionKeyType$.pipe(
    map(keyType => keyType.getSelectionValue())
  );

  public columnSectionName$ = this.columnSectionKeyType$.pipe(
    map(keyType => keyType.getSelectionTitle())
  );

  public canSubmitForm$ = combineLatest([
    this.unsavedChangesInModal$,
    this.canChangeTabs$,
    this.columnsWidthTotalError$,
  ]).pipe(
    map(([unsavedChanges, canChangeTabs, columnWidthTotalError]) => {
      return unsavedChanges && canChangeTabs && !columnWidthTotalError;
    })
  );

  public static disabledForGridLayout(key: string, layoutType: SectionLayoutType): boolean {
    // Only the Price dropdown should be disabled, it should always have the value of 'On'
    // Therefore we only disable the dropdown itself in the HTML component, and leave Price name and width enabled
    return layoutType?.isAnyGrid() &&
      (
        key === IndividualSectionColumnConfigProductInfoKey.Quantity ||
        key === IndividualSectionColumnConfigProductInfoKey.SecondaryPrice ||
        key === IndividualSectionColumnConfigProductInfoKey.Size
      );
  }

  public makeColumnOptionFormFromKeys$(keys: IndividualSectionColumnConfigKeyType[]): Observable<any> {
    return this.columnConfigMap$.pipe(
      map(configMap => {
        return keys?.map(keyType => {
          const key = keyType?.getSelectionValue();
          const name = keyType?.getSelectionTitle();
          const columnConfig = configMap?.get(key) ?? new SectionColumnConfig();
          if (Number.isFinite(columnConfig?.columnOpacity) && columnConfig?.columnOpacity <= 1) {
            columnConfig.columnOpacity = Math.round(columnConfig.columnOpacity * 100);
          }
          return {
            key,
            name,
            config: columnConfig
          };
        });
      })
    );
  }

  saveChangesWithinModal() {
    const columnConfigMap = this._columnConfigMap.getValue();
    columnConfigMap?.forEach((columnConfig) => {
      if (columnConfig?.columnOpacity === 0) columnConfig.columnOpacity = null;
    });
    this._columnConfigMap.next(columnConfigMap);
  }

  submitForms() {
    const lm = 'Updating Blueprint Column Options';
    combineLatest([
      this.sectionBlueprint$,
      this.columnConfigMap$
    ]).pipe(
      switchMap(([sectionBlueprint, sectionColumnConfig]) => {
        this._loadingOpts.addRequest(lm);
        sectionColumnConfig?.forEach(columnConfig => {
          if (columnConfig?.columnOpacity > 1) {
            columnConfig.columnOpacity = columnConfig.columnOpacity / 100;
          }
        });
        sectionBlueprint.columnConfig = window.injector.Deserialize.mapOf(SectionColumnConfig, sectionColumnConfig);
        const blueprintCopy = window.injector.Deserialize.instanceOf(SectionBlueprint, sectionBlueprint);
        return this.menuDomainModel.updateSectionBlueprint(blueprintCopy);
      }),
      take(1)
    ).subscribe({
      next: sectionBlueprint => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishSuccessMessage('Created new section blueprint', 'Success');
        this.activeModal.close(sectionBlueprint);
      },
      error: (error: BsError) => {
        this._loadingOpts.removeRequest(lm);
        this.toastService.publishError(error);
      }
    });
  }

}
