<app-loading *rxIf="viewModel.isLoading$ | push" [options]="viewModel.loadingOpts$ | push"> </app-loading>

<div class="modal-body sticky">
  <div
    #modalHeader
    appStickyAutoPosition
    [stickyCollectionKey]="'pos-sync'"
    [stickyZ]="2"
    class="sticky-header-container">
    <div class="modal-header no-bottom-border pb-none">
      <div class="modal-title">View Sync Job</div>
    </div>
  </div>

  <div class="sticky-body-container">
    <lib-reactive-form-group #SyncDataForm>
      <lib-reactive-form-text
        [inputName]="'name'"
        [label]="'Sync Job Name'"
        [editable]="false"
        [inlineLabel]="false"
        [programmaticallyChangeValue]="viewModel.syncDataJobName$ | push | dateTimeStringIfUnixStringElseOriginal">
      </lib-reactive-form-text>
    </lib-reactive-form-group>

    <div fxLayout="column" [style.gap.rem]="1" [style.margin-bottom.rem]="1">
      <div class="review-sync-results-subtitle">Locations</div>
      <div class="review-sync-results-description">View what locations the sync job was performed at.</div>
      <ng-container *rxIf="viewModel.syncDataLocationLengthIsOne$ | push; else MultipleLocations">
        <div *rxFor="let location of viewModel.syncDataLocationNames$ | push" class="review-sync-location-name">
          {{ location }}
          <hr class="no-margin" [style.margin-top.rem]="1" />
        </div>
      </ng-container>

      <ng-template #MultipleLocations>
        <lib-reactive-form-column-layout nColumns="2" [columnGap]="'0.5rem'">
          <div *rxFor="let location of viewModel.syncDataLocationNames$ | push" class="review-sync-location-name">
            {{ location }}
            <hr class="no-margin" [style.margin-top.rem]="1" />
          </div>
        </lib-reactive-form-column-layout>
      </ng-template>
    </div>

    <div fxLayout="column" [style.gap.rem]="1">
      <div class="review-sync-results-subtitle">Sync Results</div>
      <div class="review-sync-results-description">
        View what information was updated as a result of the POS sync job.
      </div>
      <hr class="no-margin" />
    </div>

    <div>
      <app-review-sync-list-item
        *ngFor="let syncType of viewModel.syncDataTypes$ | push"
        [syncType]="syncType | getSyncTypeDefinition | push"
        [syncJobResults]="syncDataJob?.results"
        [provider]="viewModel?.inventoryProvider$ | push">
      </app-review-sync-list-item>
    </div>
  </div>

  <div #footerContainer class="sticky-footer-container modal-footer-flex-end">
    <lib-button-neutral (buttonClicked)="close(null)"> Close </lib-button-neutral>
  </div>
</div>
