import { IndividualSectionColumnConfigCollectiveTerpeneKey, IndividualSectionColumnConfigIndividualTerpeneKey, IndividualSectionColumnConfigKey, IndividualSectionColumnConfigPrimaryCannabinoidKey, IndividualSectionColumnConfigProductInfoKey, IndividualSectionColumnConfigSecondaryCannabinoidKey } from '../models/enum/dto/individual-section-column-config-key';
import { TypeDefinition } from './type-definition';
import { SectionLayoutType } from './section-layout-type';

export class IndividualSectionColumnConfigKeyType extends TypeDefinition {

  override value: IndividualSectionColumnConfigKey;

  public static isKey(key: string|IndividualSectionColumnConfigKey): boolean {
    const keys: IndividualSectionColumnConfigKey[] = [
      ...Object.values(IndividualSectionColumnConfigProductInfoKey),
      ...Object.values(IndividualSectionColumnConfigPrimaryCannabinoidKey),
      ...Object.values(IndividualSectionColumnConfigSecondaryCannabinoidKey),
      ...Object.values(IndividualSectionColumnConfigCollectiveTerpeneKey),
      ...Object.values(IndividualSectionColumnConfigIndividualTerpeneKey)
    ];
    return keys.includes(key as IndividualSectionColumnConfigKey);
  }

  static getAllKeys(): IndividualSectionColumnConfigKey[] {
    return [
      ...Object.values(IndividualSectionColumnConfigProductInfoKey),
      ...Object.values(IndividualSectionColumnConfigPrimaryCannabinoidKey),
      ...Object.values(IndividualSectionColumnConfigSecondaryCannabinoidKey),
      ...Object.values(IndividualSectionColumnConfigCollectiveTerpeneKey),
      ...Object.values(IndividualSectionColumnConfigIndividualTerpeneKey)
    ];
  }

  static getSortOrder(): IndividualSectionColumnConfigKey[] {
    return [
      IndividualSectionColumnConfigProductInfoKey.Asset,
      IndividualSectionColumnConfigProductInfoKey.Badges,
      IndividualSectionColumnConfigProductInfoKey.Brand,
      IndividualSectionColumnConfigProductInfoKey.StrainType,
      IndividualSectionColumnConfigProductInfoKey.Quantity,
      IndividualSectionColumnConfigProductInfoKey.Size,
      IndividualSectionColumnConfigProductInfoKey.QuantityAndSize,
      IndividualSectionColumnConfigProductInfoKey.Stock,
      ...Object.values(IndividualSectionColumnConfigPrimaryCannabinoidKey),
      ...Object.values(IndividualSectionColumnConfigSecondaryCannabinoidKey),
      ...Object.values(IndividualSectionColumnConfigCollectiveTerpeneKey),
      ...Object.values(IndividualSectionColumnConfigIndividualTerpeneKey),
      IndividualSectionColumnConfigProductInfoKey.Price,
      IndividualSectionColumnConfigProductInfoKey.SecondaryPrice,
    ];
  }

  public isStockKey(): boolean {
    return this.value === IndividualSectionColumnConfigProductInfoKey.Stock;
  }

  public isCannabinoidKey(): boolean {
    return this.isPrimaryCannabinoidKey() || this.isSecondaryCannabinoidKey();
  }

  public isPrimaryCannabinoidKey(): boolean {
    return Object.values(IndividualSectionColumnConfigPrimaryCannabinoidKey).includes(this.value as any);
  }

  public isSecondaryCannabinoidKey(): boolean {
    return Object.values(IndividualSectionColumnConfigSecondaryCannabinoidKey).includes(this.value as any);
  }

  public isTerpeneKey(): boolean {
    return this.isCollectiveTerpeneKey() || this.isIndividualTerpeneKey();
  }

  public isCollectiveTerpeneKey(): boolean {
    return Object.values(IndividualSectionColumnConfigCollectiveTerpeneKey).includes(
      this.value as IndividualSectionColumnConfigCollectiveTerpeneKey
    );
  }

  public isIndividualTerpeneKey(): boolean {
    return Object.values(IndividualSectionColumnConfigIndividualTerpeneKey).includes(this.value);
  }

  public static disabledForGridLayout(key: string, layoutType: SectionLayoutType): boolean {
    const colsDisabledForGridLayout = [
      IndividualSectionColumnConfigProductInfoKey.Quantity,
      IndividualSectionColumnConfigProductInfoKey.QuantityAndSize,
      IndividualSectionColumnConfigProductInfoKey.Size,
      IndividualSectionColumnConfigProductInfoKey.SecondaryPrice,
    ];
    const disableForGridLayout = colsDisabledForGridLayout.includes(key as IndividualSectionColumnConfigProductInfoKey);
    return layoutType?.isAnyGrid() && disableForGridLayout;
  }

}
