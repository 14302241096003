// noinspection JSUnusedLocalSymbols

import { Injectable, Type } from '@angular/core';
import { UtilsAPI } from '../api/utils-api';
import { CacheService } from './cache-service';
import { BsError } from '../models/shared/bs-error';
import { ToastService } from './toast-service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BaseService } from '@mobilefirstdev/base-angular';
import { EnumTypes } from '../utils/enum-types';
import { TypeDefinition } from '../utils/type-definition';
import { ProvinceCode, ProvinceType } from '../utils/province-type';
import { VariantLookupTypeDefinition } from '../utils/variant-lookup-type-definition';
import { SectionLayoutType } from '../utils/section-layout-type';
import { AssetLibraryType } from '../utils/asset-library-type';
import { DefaultPrintSizeType } from '../utils/default-print-size-type';
import { DefaultDigitalSizeType } from '../utils/default-digital-size-type';
import { VariantTypeDefinition } from '../utils/variant-type-definition';
import { SectionColumnConfigDataValueType } from '../utils/section-column-config-data-value-type';
import { SortUtils } from '../utils/sort-utils';
import { SectionColumnConfigDefaultState } from '../utils/section-column-config-default-state-type';
import { DefaultPrintCardSizeType } from '../utils/default-print-card-size-type';
import { Selectable } from '../models/protocols/selectable';
import * as uuid from 'uuid';
import { exists } from '../functions/exists';
import { DefaultPrintLabelSizeType } from '../utils/default-print-label-size-type';
import { DefaultStackedSizeType } from '../utils/default-stacked-size-type';
import { SectionColumnConfigSecondaryPricingData } from '../models/enum/dto/section-column-config-secondary-pricing-data';
import { SectionColumnConfigAssetData } from '../models/enum/dto/section-column-config-asset-data';
import { SectionColumnConfigStrainTypeData } from '../models/enum/dto/section-column-config-strain-type-data';
import { SectionColumnConfigDisplayFormatData } from '../models/enum/dto/section-column-config-display-format-data';

declare global {
  interface Window {
    types: TypeService | undefined;
  }
}

@Injectable({providedIn: 'root'})
export class TypeService extends BaseService {

  constructor(
    private cacheService: CacheService,
    private utilsAPI: UtilsAPI,
    private toastService: ToastService
  ) {
    super();
    this.init();
    window.types = this;
  }

  // Base Type object
  public _rootTypes: BehaviorSubject<EnumTypes> = new BehaviorSubject<EnumTypes>(null);
  public rootTypes$ = this._rootTypes.asObservable();
  private listenToRootTypes = this.rootTypes$.notNull().subscribeWhileAlive({
    owner: this,
    next: types => {
      this._sectionLayoutTypes.next(types?.sectionLayoutType);
      this._printCardSizeTypes.next(types?.defaultPrintCardSizes);
      this._printLabelSizeTypes.next(types?.defaultPrintLabelSizes);
      this._printSizeTypes.next(types?.defaultPrintSizes);
      this._digitalSizeTypes.next(types?.defaultDigitalSizes);
      this._sectionColumnConfigDataValues.next(types?.sectionColumnConfigDataValue);
    }
  });

  // Easily accessible pipes for each enum value
  public assetLibraryTypes$ = this.rootTypes$.pipe(map(types => types?.assetLibraryType));
  public assetLibraryBrandAssetGroupTypes$ = this.assetLibraryTypes$.pipe(
    map(types => {
      return types.filter(t => {
        return !t.value.includes('Custom')
          && t.value !== AssetLibraryType.Product
          && t.value !== AssetLibraryType.Package;
      });
    })
  );
  public assetLibraryAssetGroupTypes$ = this.assetLibraryTypes$.pipe(
    map(types => types.filter(t => !t.value.includes('Custom') &&
      t.value !== AssetLibraryType.Brand &&
      t.value !== AssetLibraryType.PrimaryBrand &&
      t.value !== AssetLibraryType.AlternateBrand))
  );

  public cannabinoidDisplayTypes$ = this.rootTypes$.pipe(map(types => types?.cannabinoidDisplayType));
  public cannabisUnitOfMeasures$ = this.rootTypes$.pipe(map(types => types?.cannabisUnitOfMeasure));
  public comboMenuCardTypes$ = this.rootTypes$.pipe(map(types => types?.comboMenuCardType));
  public companyRoles$ = this.rootTypes$.pipe(map(types => types?.companyRole));

  // Country, Province, and State Dropdowns
  public readonly countries$ = this.rootTypes$.pipe(map(types => types?.country));
  private readonly provincesMap$ = this.rootTypes$.pipe(map(types => types?.province));
  private readonly provinces$ = this.provincesMap$.pipe(
    map(typesMap => [...(typesMap?.values() || [])]?.flatMap(typeDefinitions => typeDefinitions))
  );

  public readonly provinceAndStateDropdowns$ = combineLatest([
    this.provincesMap$,
    this.countries$
  ]).pipe(
    map(([provinceMap, countries]) => {
      const provinceAndStateDropdowns = [];
      countries?.forEach(country => {
        const countryHeader = this.buildDropDownHeader(country?.getSelectionTitle());
        const countryCode = country?.getSelectionValue();
        const provinces = provinceMap?.get(countryCode);
        provinceAndStateDropdowns.push(countryHeader, ...provinces);
      });
      return provinceAndStateDropdowns;
    })
  );

  public provincesForLookup$ = this.provinces$.pipe(
    map(provinces => {
      const provinceCodesForLookup = [
        ProvinceCode.AB,
        ProvinceCode.BC,
        ProvinceCode.MB,
        ProvinceCode.ON,
      ];
      return provinces?.filter(province => provinceCodesForLookup.contains(province.value));
    })
  );

  // Print Card Properties
  public readonly printCardProperties$ = this.rootTypes$.pipe(
    map(types => types?.printCardProperty),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly printCardProductPropertyList$ = this.printCardProperties$.pipe(
    map(properties => properties?.filter(p => p?.isProductProperty()))
  );

  public readonly printCardCannabinoidPropertyList$ = this.printCardProperties$.pipe(
    map(properties => properties?.filter(p => p?.isCannabinoidProperty())),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly printCardPrimaryCannabinoidsPropertyList$ = this.printCardCannabinoidPropertyList$.pipe(
    map(properties => properties?.filter(p => p?.isPrimaryCannabinoidProperty()))
  );

  public readonly printCardSecondaryCannabinoidsPropertyList$ = this.printCardCannabinoidPropertyList$.pipe(
    map(properties => properties?.filter(p => p?.isSecondaryCannabinoidProperty()))
  );

  public readonly printCardTerpenePropertyList$ = this.printCardProperties$.pipe(
    map(properties => properties?.filter(p => p?.isTerpeneProperty())),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly printCardCollectiveTerpenePropertyList$ = this.printCardTerpenePropertyList$.pipe(
    map(properties => properties?.filter(p => p?.isCollectiveTerpeneProperty()))
  );

  public readonly printCardIndividualTerpenePropertyList$ = this.printCardTerpenePropertyList$.pipe(
    map(properties => properties?.filter(p => p?.isIndividualTerpeneProperty()))
  );

  // Print Card Paper Size Type
  public printCardPaperSizeTypes$ = this.rootTypes$.pipe(map(types => types?.defaultPrintCardPaperSizes));

  // Print Card Size Type
  public PrintCardSizeType: typeof DefaultPrintCardSizeType = DefaultPrintCardSizeType;
  protected _printCardSizeTypes = new BehaviorSubject<DefaultPrintCardSizeType[]>(null);
  public printCardSizeTypes$ = this._printCardSizeTypes.asObservable();

  // Print Size Type
  public PrintSizeType: typeof DefaultPrintSizeType = DefaultPrintSizeType;
  protected _printSizeTypes = new BehaviorSubject<DefaultPrintSizeType[]>(null);
  public printSizeTypes$ = this._printSizeTypes.asObservable();

  // Print Label Paper Size Type
  public printLabelPaperSizeTypes$ = this.rootTypes$.pipe(map(types => types?.defaultPrintLabelPaperSizes));

  // Print Label
  private _printLabelSizeTypes = new BehaviorSubject<DefaultPrintLabelSizeType[]>(null);
  public printLabelSizeTypes$ = this._printLabelSizeTypes as Observable<DefaultPrintLabelSizeType[]>;

  public readonly printStackSizeTypes$: Observable<DefaultStackedSizeType[]> = combineLatest([
    this.printCardSizeTypes$,
    this.printLabelSizeTypes$
  ]).pipe(
    map(([printCardSizes, printLabelSizes]) => {
      if (!printCardSizes && !printLabelSizes) return null;
      return [...(printCardSizes || []), ...(printLabelSizes || [])];
    })
  );

  // Digital Size Type
  public DigitalSizeType: typeof DefaultDigitalSizeType = DefaultDigitalSizeType;
  protected _digitalSizeTypes = new BehaviorSubject<DefaultDigitalSizeType[]>(null);
  public digitalSizeTypes$ = this._digitalSizeTypes.asObservable();

  // Section Layout Types
  public SectionLayoutType: typeof SectionLayoutType = SectionLayoutType;
  protected _sectionLayoutTypes = new BehaviorSubject<SectionLayoutType[]>(null);
  public sectionLayoutTypes$ = this._sectionLayoutTypes as Observable<SectionLayoutType[]>;

  public featuredCategoryMenuCardTypes$ = this.rootTypes$.pipe(map(types => types?.featuredCategoryMenuCardType));
  public fontStyles$ = this.rootTypes$.pipe(map(types => types?.fontStyle));

  public groupDetailAppearance$ = this.rootTypes$.pipe(map(types => types?.groupDetailAppearance));
  public groupDetailAromaFlavor$ = this.rootTypes$.pipe(map(types => types?.groupDetailAromaFlavor));
  public groupDetailEffect$ = this.rootTypes$.pipe(map(types => types?.groupDetailEffect));
  public groupDetailEnergyScore$ = this.rootTypes$.pipe(map(types => types?.groupDetailEnergyScore));
  public groupDetailGrowingCondition$ = this.rootTypes$.pipe(map(types => types?.groupDetailGrowingCondition));
  public groupDetailHelpsWith$ = this.rootTypes$.pipe(map(types => types?.groupDetailHelpsWith));

  public individualSectionColumnConfigKeys$ = this.rootTypes$.pipe(
    map(types => types?.individualSectionColumnConfigKey)
  );

  public individualCannabinoidSectionColumnConfigKeys$ = this.individualSectionColumnConfigKeys$.pipe(
    map(keys => keys?.filter(k => k?.isCannabinoidKey()))
  );

  public individualTerpeneSectionColumnConfigKeys$ = this.individualSectionColumnConfigKeys$.pipe(
    map(keys => keys?.filter(k => k?.isTerpeneKey()))
  );

  public inventoryProviders$ = this.rootTypes$.pipe(
    map(types => types?.inventoryProvider),
    map(providers => providers?.sort(SortUtils.sortTypeDefinitionsByName)),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public menuLabels$ = this.rootTypes$.pipe(map(types => types?.menuLabel));
  public menuPreviewJobStatuses$ = this.rootTypes$.pipe(map(types => types?.menuPreviewJobStatus));
  public menuStyleObjects$ = this.rootTypes$.pipe(map(types => types?.menuStyleObject));
  public menuSubTypes$ = this.rootTypes$.pipe(map(types => types?.menuSubType));
  public menuTypes$ = this.rootTypes$.pipe(map(types => types?.menuType));
  public optionScales$ = this.rootTypes$.pipe(map(types => types?.optionScale));
  public orientations$ = this.rootTypes$.pipe(map(types => types?.orientation));
  public overflowStates$ = this.rootTypes$.pipe(map(types => types?.overflowState));
  public priceFormats$ = this.rootTypes$.pipe(map(types => types?.priceFormat));
  public printFooterLayouts$ = this.rootTypes$.pipe(map(types => types?.printFooterLayout));
  public printHeaderLayouts$ = this.rootTypes$.pipe(map(types => types?.printHeaderLayout));
  public productTypes$ = this.rootTypes$.pipe(map(types => types?.productType));
  public promotionConditions$ = this.rootTypes$.pipe(map(types => types?.promotionCondition));
  public promotionDiscounts$ = this.rootTypes$.pipe(map(types => types?.promotionDiscount));
  public promotionPeriods$ = this.rootTypes$.pipe(map(types => types?.promotionPeriod));
  public saleLabelFormats$ = this.rootTypes$.pipe(map(types => types?.saleLabelFormat));
  public secondaryCannabinoids$ = this.rootTypes$.pipe(
    map(types => types?.secondaryCannabinoids?.sort(SortUtils.sortTypeDefinitionsByName))
  );

  // Section Column Config Data Values
  public _sectionColumnConfigDataValues = new BehaviorSubject<SectionColumnConfigDataValueType[]>(null);
  public sectionColumnConfigDataValues$ = this._sectionColumnConfigDataValues.asObservable();

  public secondaryPriceColumnConfigDataValues$ = this.sectionColumnConfigDataValues$.pipe(
    map(types => types?.filter(type => {
      return Object.values(SectionColumnConfigSecondaryPricingData)
        ?.includes(type?.value as SectionColumnConfigSecondaryPricingData);
    }))
  );

  public assetColumnConfigDataValues$ = this.sectionColumnConfigDataValues$.pipe(
    map(types => types?.filter(type => {
      return Object.values(SectionColumnConfigAssetData)
        ?.includes(type?.value as SectionColumnConfigAssetData);
    }))
  );

  public strainTypeColumnConfigDataValues$ = this.sectionColumnConfigDataValues$.pipe(
    map(types => types?.filter(type => {
      return Object.values(SectionColumnConfigStrainTypeData)
        ?.includes(type?.value as SectionColumnConfigStrainTypeData);
    }))
  );

  public displayFormatTypeColumnConfigDataValues$ = this.sectionColumnConfigDataValues$.pipe(
    map(types => types?.filter(type => {
      return Object.values(SectionColumnConfigDisplayFormatData)
        ?.includes(type?.value as SectionColumnConfigDisplayFormatData);
    }))
  );

  public sectionColumnConfigDefaultStates$ = this.rootTypes$.pipe(map(types => types?.sectionColumnConfigDefaultState));
  public autoSectionColumnConfigDefaultStates$ = this.sectionColumnConfigDefaultStates$.pipe(
    map(defaultStates => defaultStates?.filter(ds => ds?.value !== SectionColumnConfigDefaultState.Disabled))
  );
  public noAutoSectionColumnConfigDefaultStates$ = this.autoSectionColumnConfigDefaultStates$.pipe(
    map(defaultStates => defaultStates?.filter(ds => ds.value !== SectionColumnConfigDefaultState.Auto))
  );
  public disabledSectionColumnConfigDefaultStates$ = this.sectionColumnConfigDefaultStates$.pipe(
    map(defaultStates => defaultStates?.filter(ds => ds?.value === SectionColumnConfigDefaultState.Disabled))
  );
  public sectionColumnConfigKeys$ = this.rootTypes$.pipe(map(types => types?.sectionColumnConfigKey));

  public sectionSortOptions$ = this.rootTypes$.pipe(
    map(types => types?.sectionSortOption),
    map(options => options?.sort(SortUtils.sortSectionSortTypesByName))
  );
  public sectionTypes$ = this.rootTypes$.pipe(map(types => types?.sectionType));
  public sizeUnits$ = this.rootTypes$.pipe(map(types => types?.sizeUnit));
  public smartFilterSystemIdentifiers$ = this.rootTypes$.pipe(map(types => types?.smartFilterSystemIdentifiers));
  public strainTypes$ = this.rootTypes$.pipe(map(types => types?.strainType));
  public syncTypes$ = this.rootTypes$.pipe(map(types => types?.syncType));
  public terpenes$ = this.rootTypes$.pipe(map(types => types?.terpenes));
  public terpeneUnitOfMeasure$ = this.rootTypes$.pipe(map(types => types?.terpeneUnitOfMeasure));
  public timezoneMap$ = this.rootTypes$.pipe(map(types => types?.timezone));
  public timezones$ = this.timezoneMap$.pipe(
    map(typesMap => [...(typesMap?.values() || [])]?.flatMap(typeDefinitions => typeDefinitions))
  );
  public unitOfMeasures$ = this.rootTypes$.pipe(map(types => types?.unitOfMeasure));
  public universalVariantStatus$ = this.rootTypes$.pipe(map(types => types?.universalVariantStatus));
  public usePurposes$ = this.rootTypes$.pipe((map(types => types?.usePurpose)));
  public variantLookupTypes$ = this.rootTypes$.pipe(map(types => types?.variantLookupType));
  public variantProperties$ = this.rootTypes$.pipe(map(types => types?.variantProperty));

  // Variant Types
  public variantTypesMap$ = this.rootTypes$.pipe(map(types => types?.variantType));
  public variantTypes$ = this.variantTypesMap$.pipe(
    map(typesMap => [...typesMap?.values() || []]?.flatMap(typeDefinitions => typeDefinitions))
  );

  public groupedSectionSortOptions$ = combineLatest([
    this.sectionSortOptions$,
    this.secondaryCannabinoids$,
    this.terpenes$
  ]).pipe(
    map(([sectionSortOptions, secondaryCannabinoidsTypes, terpenesTypes]) => {
      const secondaryCannabinoids = secondaryCannabinoidsTypes?.map(sc => sc?.getSelectionValue());
      const terpenes = terpenesTypes?.map(t => t?.getSelectionValue());
      const productSortOptions = sectionSortOptions?.filter(opt => {
        return !exists(opt?.metadata);
      });
      const secondaryCannabinoidSortOptions = sectionSortOptions?.filter(opt => {
        return secondaryCannabinoids?.includes(opt?.metadata);
      });
      const terpeneSortOptions = sectionSortOptions?.filter(opt => {
        return terpenes?.includes(opt?.metadata);
      });
      return [
        this.buildDropDownHeader('Product Info'),
        ...(productSortOptions ?? []),
        this.buildDropDownHeader('Secondary Cannabinoids'),
        ...(secondaryCannabinoidSortOptions ?? []),
        this.buildDropDownHeader('Terpenes'),
        ...(terpeneSortOptions ?? [])
      ];
    })
  );

  /**
   * Iterate over all properties on the rootTypes object and find a matching
   * TypeDefinition of the type T with associated key
   */
  public initTypeDefinition<T extends TypeDefinition>(type: Type<T>, key: string): T {
    if (!!key) {
      const enumTypes = this._rootTypes.getValue();
      for (const enumTypesKey in enumTypes) {
        if (enumTypes.hasOwnProperty(enumTypesKey)) {
          const enumType = enumTypes[enumTypesKey] as Map<string, TypeDefinition[]> | TypeDefinition[];
          let typeDefinitions: TypeDefinition[] = [];
          if (enumType instanceof Map) {
            // EnumType is Map<string, TypeDefinition[]>
            typeDefinitions = [...enumType?.values() || []]?.flatMap(definitions => definitions);
          } else if (enumType instanceof Array) {
            typeDefinitions = enumType as TypeDefinition[];
          }
          // EnumType is TypeDefinition[]
          const typeDefinitionResult = typeDefinitions?.find(td => td instanceof type && td.value === key);
          if (!!typeDefinitionResult) {
            return typeDefinitionResult as T;
          }
        }
      }
    }
    return null;
  }

  public getProvincesForCountry(countryCode: string): Observable<ProvinceType[]> {
    return this.provincesMap$.pipe(map(provinceMap => provinceMap?.get(countryCode) ?? []));
  }

  public getTimeZonesForCountry(countryCode: string): Observable<TypeDefinition[]> {
    return this.timezoneMap$.pipe(map(timezoneMap => timezoneMap?.get(countryCode) ?? []));
  }

  public getVariantLookupTypesForProvince(provinceCode: ProvinceCode): Observable<VariantLookupTypeDefinition[]> {
    return VariantLookupTypeDefinition.getVariantLookupTypesForProvince(this.variantLookupTypes$, provinceCode);
  }

  public getVariantTypesForProductType(productType: string): Observable<VariantTypeDefinition[]> {
    return this.variantTypesMap$.pipe(map(variantTypesMap => variantTypesMap?.get(productType) ?? []));
  }

  public init() {
    this.loadTypes();
  }

  public loadTypes() {
    // Want to use cached value as fallback since the app wont work without these enum values
    const cachedTypes = this.cacheService.getCachedObject<EnumTypes>(EnumTypes, EnumTypes.getCacheKey());
    if (!!cachedTypes) {
      // Set the types right away in case API fails or is delayed
      this.setTypes(cachedTypes);
    }
    // Get the types from the API and update the cached value
    this.utilsAPI.GetTypes().subscribe((types) => {
      this.setTypes(types);
      this.cacheService.cacheObject<EnumTypes>(EnumTypes.getCacheKey(), types);
    }, (error: BsError) => {
      this.toastService.publishError(error);
    });
  }

  public setTypes(types: EnumTypes) {
    if (!!types) {
      this._rootTypes.next(types);
    }
  }

  private buildDropDownHeader = (title: string): Selectable => new class implements Selectable {

    getSelectionTitle = (): string => `---- ${title} ----`;
    getSelectionUniqueIdentifier = (): string => uuid?.v4();
    getSelectionValue = (): any => undefined;
    getSelectionIsDisabled = (): boolean => true;

  }();

  override destroy() {
    super.destroy();
    window.types = undefined;
  }

}
